/* Project imports */
@import 'colors';

// Removing Colors
$theme-colors: map-remove($theme-colors, "blue", "indigo", "purple", "pink", "red",
    "orange", "yellow", "green", "teal", "cyan");

// DEPRECATED
$theme-colors: (
  "primary":          $color__auerswald-blue,
  "secondary":        $color__dark-blue,
  "pale-blue":        $color__pale-blue,
  "middle-blue":      $color__middle-blue,
  "light-blue":       $color__light-blue
);

// Round corner
$enable-rounded:              true;
$border-radius:               .5rem;

// Body settings
$body-bg:                     $white;
$body-color:                  $color__dark-grey;

// Button
$btn-padding-y:               1.25rem;
$btn-padding-x:               2.25rem;

// Links
$link-color:                  $color__dark-grey;
$link-hover-color:            $color__dark-blue;
$link-decoration:             underline;

$headings-font-weight:        300;
$headings-color:              $color__dark-blue;

// Breadcrumps
$breadcrumb-divider-color:    $color__dark-blue;
$breadcrumb-active-color:     $color__dark-blue;
