input.form-control {

  &:focus {
    background-color: transparent;
  }

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $forms_color;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: $forms_color;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: $forms_color;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: $forms_color;
  }

  height: 60px;
  width: 100%;

  padding: 12px 20px;
  margin: 0;
  box-sizing: border-box;

  color: $forms_color;

  border: 1px solid $forms_border_color;
  background-color: transparent;
}

select.form-control {

  &:focus {
    background-color: transparent;
  }

  height: 60px;
  width: 100%;

  color: $forms_color;

  background-color: transparent;
  border: 1px solid $forms_border_color;
}
