// General styles
header {

  position: fixed !important;

  height: $navigation_height_responsive;
  background-color: $navigation_bg;

  -webkit-transition: background-color .5s ease-out;
  transition: background-color .5s ease-out;

  z-index: 9998;

  @include media-breakpoint-up(lg) {
    height: $navigation_height;

    @if variable-exists(fontevo) {
      padding: 1.25rem 0 0 0 !important;
    }
  }

  &.navbar {
    padding: 0;

    @include media-breakpoint-up(lg) {

      @if variable-exists(fontevo) {
        padding: 1.25rem 0 0 0 !important;
      }
    }
  }

  .container {
    height: 100%;
  }

  .logo-born {

    display: none;
    position: absolute;

    top: 5.9375rem;
    width: 7.27375rem;

    margin-left: 2.25rem;

    background-color: #ffffff;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);

    @include media-breakpoint-up(lg) {
      @if variable-exists(fontevo) {
        top: 10rem;
        width: 12.5rem;
        margin-left: 0;
      } @else {
        display: none;
      }
    }

    & img {

      width: 100%;
      height: auto;

      display: block;
      position: relative;
    }
  }

  .navbar-brand {
    padding: 0;
    margin-left: 2.25rem;

    @if variable-exists(fontevo) {
      position: absolute;

      top: 0;
      z-index: 9999;

      background-color: #ffffff;
      box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);

      padding: 1.45rem;
    }

    @include media-breakpoint-up(lg) {
      margin-left: 0;

      @if variable-exists(fontevo) {
        padding: 2.5rem;
      }
    }

    img {
      width: auto;

      @if variable-exists(fontevo) {
        width: 70px;
        display: block;
        position: relative;
      } @else {
        height: 45px;
      }

      @include media-breakpoint-up(lg) {
        @if variable-exists(fontevo) {
          width: 120px;
        } @else {
          height: 80px;
        }
      }
    }
  }

  .navbar-collapse {
    position:absolute;
    top: $navigation_height_responsive;
    width: 100%;

    @include media-breakpoint-up(lg) {
      position: inherit;
      top: inherit;
      width: inherit;
      height: 100%;
    }
  }
}
