//-------------------------
// ✏️ Typography
//-------------------------

$base-font-size: 16px !default; // Default, Use with em() and rem() functions

@function rem($px) {
  @return ($px / $base-font-size) * 1rem;
}

@function em($px) {
  @return ($px / $base-font-size) * 1em;
}

// Only applied to bold weight text
@mixin font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin letter-spacing {
  letter-spacing: 0;
}

// Bootstrap Variables
// Typography
$h1-font-size:                $font-size-base * 6;
$h2-font-size:                $font-size-base * 4.5;
$h3-font-size:                $font-size-base * 3;
$h4-font-size:                $font-size-base * 2.25;
$h5-font-size:                $font-size-base * 1.5;
$h6-font-size:                $font-size-base * 1.15;


h1 {
  font-weight: normal;
}

h2 {
  font-weight: normal;
}

h3 {
  font-weight: 100;
}

h4 {
  font-weight: 100;
}

h5 {
  font-weight: 100;
}

h6 {
  font-weight: bold;
  font-family: $font-family-secondary;
}
