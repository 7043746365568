.product {

  &-subtitle {
    margin-left: 2rem;

    font-size: 2rem;
    font-weight: lighter;
  }

  &.card {
    padding-left: 0;
    padding-right: 0;

    border: none;

    background-color: transparent;

    & > a {
      height: 100%;
      position: relative;
    }

    & img {
      height: 11.25rem;
      margin: 0 1.25rem;

      // #1085 Image resizing
      &.eightyfive {
        -webkit-transform: scale(0.85);
        -moz-transform: scale(0.85);
        -ms-transform: scale(0.85);
        -o-transform: scale(0.85);
        transform: scale(0.85);
      }

      &.seventy {
        -webkit-transform: scale(0.7);
        -moz-transform: scale(0.7);
        -ms-transform: scale(0.7);
        -o-transform: scale(0.7);
        transform: scale(0.7);
      }
    }
  }

  &-newbadge {
    position: absolute;
    top: 0;
    left: 1.25rem;
    z-index: $zindex-fixed;
  }

  &-threesixtybadge {
    position: absolute;
    top: 0;
    right: 1.25rem;
    z-index: $zindex-fixed;
  }

  /*&__body {

    & > .product__top {
      @extend .product__image;



      & > picture > img {
        max-width: 100%;
      }
    }
  }

  & > .card-body {

    padding: 1.25rem .25rem 0 1.25rem;

    & > a {

      &:hover {
        color: $white;
        text-decoration: underline;
      }
    }

    @include media-breakpoint-up(lg) {
      margin: 1.25rem 2.25rem 0 1.25rem;
      padding: 0;
    }
  }

  &__sectionheader {
    &--subtitle {
      margin-left: 2rem;

      @include rfs(32px);
      font-weight: lighter;
    }
  }

  &__title {
    @extend .card-title;

    margin-bottom: 0.25rem;
  }

  & p {
    color: $white;
  }

  &__tag {
    position: relative;

    &::before {
    }
  }

  &__image {
    height: 11.25rem;
    margin: 0 1.25rem;

    // #1085 Image resizing
    &--eightyfive {
      -webkit-transform: scale(0.85);
      -moz-transform: scale(0.85);
      -ms-transform: scale(0.85);
      -o-transform: scale(0.85);
      transform: scale(0.85);
    }

    &--seventy {
      -webkit-transform: scale(0.7);
      -moz-transform: scale(0.7);
      -ms-transform: scale(0.7);
      -o-transform: scale(0.7);
      transform: scale(0.7);
    }
  }*/
}
