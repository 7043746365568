.support {

  &__nav--item {
    @extend .nav-item;

    flex-grow: 1;

    &:first-child {
      border-left: 2px solid #fff;
    }
  }

  &__inner {
    overflow-x: scroll;

    padding: 0;
    margin: 1.5rem;

    @include media-breakpoint-up(lg) {
      padding: 3.5rem 5.3rem;
      overflow-x: inherit;
    }
  }

  &__accordion {

    @extend .card;

    margin-bottom: 0.75rem;

    &--header {
      padding-bottom: 0.5rem;
    }

    &--body {
      padding: 0 1.25rem 1.75rem 0;
      color: theme-color('ci-grey');
    }

    &--icon {

      align-self: center;
      padding-right: .5rem;
    }

    &--link {

      display: block;
      position: relative;

      width: 100%;
      padding-left: 3.25rem;

      &:hover {
        text-decoration: underline;
        color: theme-color('primary');
      }

      &:after {
        content: "\f13a"; /* fa-chevron-down */

        font-family: "Font Awesome 5 Free";
        font-size: 2.25rem;
        font-weight: 900;
        -webkit-font-smoothing: antialiased;

        transition: all 0.5s;

        position: absolute;
        left: 0;
        top: .35rem;
      }

      &[aria-expanded="true"]:after {
        -webkit-transform: rotate(180deg);
	      -moz-transform: rotate(180deg);
	      transform: rotate(180deg);
      }
    }
  }

  &__table {
    @extend .table;

    margin: 0 auto;

    &--paragraph {
      margin: 0;
    }

    &--noborder td {
      border-top: none !important;
    }

    td, th {
      border-top: 2px solid #E8E8E8;

      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }

    th {
      text-align: center;
      vertical-align: middle;

      font-size: 1.5rem;

      color: theme-color('primary');
    }
  }

  &__content--hide {
    padding: 1.5rem 5rem !important;
  }

  &__tab {
    @extend .nav-link;

    height: 80px;

    font-size: 0;

    background-color: theme-color('primary');
    color: $white;

    border-top: 2px solid $white;
    border-right: 2px solid $white;

    @include media-breakpoint-up(lg) {
      font-size: 1.15rem;
      line-height: 1.2rem;
    }

    &.active {
      background-color: $white !important;
      color: theme-color('primary') !important;
    }

    &:hover {
      background-color: $white;
      color: theme-color('primary');
    }
  }

  &__content {
    @extend .tab-content;
  }
}
