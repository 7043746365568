// Production Env
$img-au-path: '../../../auerswald/src/assets/';
@if variable-exists(env) {
  @if $env == production {
    $img-path: '../';
  }
}

// Navigation
$navigation_height: 						6.5rem;
$navigation_height_responsive: 	4.125rem;

$navigation_bg:  								$color__white;
$navigation_mobile_bg:          $color__pale-blue;

$navigation_meta_bg: 						$color__pale-blue;
$navigation_meta_color:					$color__dark-blue;

$navigation_mobile_divider:     $color__auerswald-blue;
$navigation_mobile_color:       $color__dark-blue;

$navigation_text_color:  				$color__dark-blue;

$navigation_submenu_bg:         $color__pale-blue;
$navigation-submenu-hr:         $color__dark-blue;

// Footer
$footer_color:									$color__dark-blue;
$footer_subline_color:					$color__auerswald-blue;

//Breadcrumb
$breadcrumb_color:              $color__dark-blue;

//Forms
$forms_color:                   $color__dark-blue;
$forms_border_color:            $color__dark-blue;

//Button
$button_text_color:             $color__dark-blue;

//Badges
$badge_bg:                      $color__dark-blue;
$badge_color:                   $color__white;

$badge_highlight_bg:            $color__orange;
$badge_highlight_color:         $color__white;

$badge_new_bg:                  $color__orange;
$badge_new_color:               $color__white;

$badge_threeSixty_bg:           transparent;
$badge_threeSixty_color:        $color__dark-blue;

//Products
$productfilter_bg:              $color__pale-blue;
$productfilter_button_bg:       $color__white;
$productfilter_button_color:    $color__dark-blue;

$productfilter_buttonactive_bg: $color__dark-blue;
$productfilter_buttonactive_color: $color__white;
