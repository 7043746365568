.badge {

  @include media-breakpoint-up(lg) {
    padding: 0.45em 0.4em;
    margin-top: 0.5rem;
    font-size: 1rem;
  }

  padding: 0.25em 0.25em;
  margin-top: 0.5rem;

  text-decoration: none;
  vertical-align: middle;

  font-weight: normal;
  font-size: .9rem;

  & > i {
    padding-right: 0.5rem;
    font-size: 1.5rem;
  }
}

.badge-normal {
  color: $badge_color;
  background-color: $badge_bg;
}

.badge-outline {

  color: $badge_bg;
  background-color: transparent;

  border: 1px solid $badge_bg;
}

.badge-highlight {

  background-color: $badge_highlight_bg;

  border-radius: 5px;
  padding: 0.3rem 0.4rem;

  color: $badge_highlight_color;

  font-weight: normal;
  font-size: 2rem;
  text-transform: uppercase;

  font-family: $font-family-sans-serif;
}

.badge-new {

  @include media-breakpoint-up(lg) {
    font-size: 2.9rem;
    padding: 0.4rem 1rem;
    border-radius: 10px;
  }

  background-color: $badge_new_bg;

  margin: 0;
  padding: 0.3rem 0.4rem;
  border-radius: 5px;

  color: $badge_new_color;

  font-size: 2rem;
  font-weight: normal;
  text-transform: uppercase;

  font-family: $font-family-sans-serif;
}

.badge-threesixty {

  & > .btn__icon {
    font-size: 1.1rem;
  }

  background-color: $badge_threeSixty_bg;

  color: $badge_threeSixty_color;

  font-size: 1.1rem;
  font-weight: bold;

  padding: 0;
  margin: 0;

  font-family: $font-family-secondary;
}
