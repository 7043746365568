a {
  font-weight: bold;
}

.btn-icon {
  font-size: 1.5rem;
  padding-right: 0.5rem;
}

.btn {
  text-decoration: none;
}

.btn-filter {

  &:hover {
    color: $white;

    background-color: theme-color('ci-green');
  }

  @extend .d-inline-flex;

  background-color: $color__white;

  font-size: 1rem;
  padding: 0.5rem 0.5rem;

  @include media-breakpoint-up(lg) {
    padding: 0.7rem 1.8rem;
    font-size: 1.25rem;
  }

  font-weight: normal;
  font-family: $font-family-sans-serif;
  color: $button_text_color;

  &.active {

    &:hover {
      color: $button_text_color;
      font-weight: bold;

      background-color: $color__white;
    }

    color: $color__white;
    font-weight: bold;

    //background-color: theme-color('ci-green');
  }
}
