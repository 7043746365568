.solution-banner {

  position: relative;

  background-repeat: no-repeat;
  background-position: 95% 40%;

  background-size: 50%;

  img {
    height: 150px;
    width: auto;
  }
}
