.resellersearch {

  input {

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: $color__pale-blue;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: $color__pale-blue;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: $color__pale-blue;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: $color__pale-blue;
    }

    color: $color__pale-blue;

    border: 1px solid $color__pale-blue;
    background-color: transparent;
  }

  select {
    color: $color__pale-blue;

    border: 1px solid $color__pale-blue;
    background-color: transparent;
  }
}
