.news {
  .card {
    border: none;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .card-body {
    border-radius: 0.25rem;
  }
}
