.btn-filter {
  &[aria-expanded=true] {
    color: $productfilter_buttonactive_color;
    background-color: $productfilter_buttonactive_bg;
  }

  &:hover {
    color: $productfilter_buttonactive_color;
    background-color: $productfilter_buttonactive_bg;
  }

  &.active {
    &:hover {
      color: $productfilter_button_color;
      font-weight: bold;

      background-color: $productfilter_button_bg;
    }

    color: $productfilter_buttonactive_color;
    font-weight: bold;

    background-color: $productfilter_buttonactive_bg;
  }

  @include media-breakpoint-up(lg) {
    padding: 0.7rem 1.8rem;
    font-size: 1.25rem;
  }

  padding: 0.5rem 0.5rem;

  font-weight: normal;
  font-size: 1rem;
  font-family: $font-family-sans-serif;

  color: $productfilter_button_color;
  background-color: $productfilter_button_bg;
}

.productfilter {
  @include media-breakpoint-up(lg) {
    position: relative;
  }

  background-color: $productfilter_bg;

  z-index: 1040;
  width: 100%;

  h5, p {
    margin: 0;
  }
}
