#navigation-main {

  &.navbar-nav {
    padding-top: 4rem;
    padding-bottom: 2rem;

    height: calc(100vh - #{$navigation_height_responsive});

    background-color: $navigation_mobile_bg;

    @include media-breakpoint-up(lg) {
      padding-top: inherit;
      padding-bottom: inherit;
      background: inherit;

      height: inherit;
    }
  }

  .nav-item {

    &:hover > .navigation-submenu {

      @include media-breakpoint-up(lg) {
        display: block !important;
      }
    }

    &:first-child {
      border-top: 1px solid $navigation_mobile_divider;

      @include media-breakpoint-up(lg) {
        border-top: inherit;
        color: inherit;
      }
    }

    color: $navigation_mobile_color;

    margin: 0 2.25rem;
    border-bottom: 1px solid $navigation_mobile_divider;

    & span.fas {
      align-items: center;

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    @include media-breakpoint-up(lg) {
      margin: inherit;
      border-bottom: inherit;
    }
  }

  .nav-link {

    &:hover {
      box-shadow: inset 0 0px 0 white, inset 0 -5px 0 $navigation_text_color;
    }

    &.secondary {
      font-weight: lighter;
    }

    font-family: $font-family-sans-serif;
    font-size: 1.55rem;
    font-weight: normal;

    text-decoration: none;

    color: $navigation_text_color;
  }

  .navigation-divider {
    @include media-breakpoint-up(lg) {
      margin-top: 0.75rem;
    }

    &::after {

      @include media-breakpoint-up(lg) {

        content: "|";

        color: $navigation_text_color;

        font-size: 1.55rem;
        line-height: 3.3rem;

        padding-right: 0.3rem;
        padding-left: 0.3rem;
      }
    }
  }

  .mobile-language {

    margin: 0 3rem;
    border-bottom: none;

    & > a {
      font-size: 1.1rem;
      color: $white !important;
      font-weight: bold;
    }

    & .flag-icon {
      font-size: 1.4rem;
    }
  }
}
