footer {

  @if variable-exists(fontevo) {
    @include gradient__right--wave(theme-color('primary'), theme-color('primary-50'), theme-color('primary-dark-90'), '../assets/images/backgrounds/bg-wave-blue-bottom.svg', bottom, scroll);
  }

  h4 {
    font-weight: bold;
    color: $footer_color;
  }

  a {
    text-decoration: none;
    font-weight: normal;
    color: $footer_color;

    &:hover {
      text-decoration: underline;
    }
  }

  .list-inline-item a {
    color: $footer_subline_color;
  }

  hr {
    border-top: 1px solid $footer_subline_color;
  }

  .logo {
    @if variable-exists(fontevo) {
      height: 115px;
      width: 200px;
    } @else {
      height: 5rem;
    }
  }
}
