.breadcrumb {

  padding: 0.75rem 0 0.75rem 0;
  margin: 0;

  .breadcrumb-item {

    &.active {

      color: $breadcrumb_color;
      font-weight: 800;
      text-decoration: underline;
    }

    & > a {
      color: $breadcrumb_color;
      text-decoration: none;
      font-weight: normal;
    }

    &:hover {

      color: $breadcrumb_color;
      text-decoration: underline;
    }
  }
}
