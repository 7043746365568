#hero-header {

  .carousel-item {

    @include media-breakpoint-up(lg) {

      min-height: 53rem;
      height: calc(100vh);
    }

    height: 100vh;

    background: no-repeat top center scroll;
    background-size: cover;
  }
}
