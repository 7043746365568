#navigation-meta {

  &.navbar-nav {

    background-color: $navigation_meta_bg;
    display: none;
    padding: 0 1rem;

    @include media-breakpoint-up(lg) {
      display: flex;
      border-radius: 0 0 $border-radius $border-radius;
    }
  }

  & .nav-link {

    color: $navigation_meta_color;
    font-size: 0.95rem;
    font-weight: normal;

    text-decoration: none;

    position: relative;

    & > i {

      font-size: 1.5rem;
      padding-right: 0.5rem;

      color: $white;

      @include media-breakpoint-up(lg) {
        font-size: 0.95rem;
        color: inherit;
      }
    }
  }

}



.nav-dropdown {

  margin: 0;
  min-width: inherit;
  text-align: right;
  border: none;

  @include media-breakpoint-up(lg) {
    // #1150 - Flag alignment (difference between prod & dev unclear)
    transform: translate3d(-2px, 38px, 0px) !important;
  }
}

.nav-dropdownitem {
  @extend .dropdown-item;

  padding: 0.25rem 0.75rem;
  color: $navigation_meta_color;
}

.nav-language {
  @include media-breakpoint-up(lg) {
    position: relative;
  }
}
