.navigation-submenu {

  & .row {
    width: 100%;
  }

  .btn__icon {
    font-size: 1.5rem;
    padding-right: 0.5rem;
  }

    @include media-breakpoint-up(lg) {
      top: $navigation_height;
      height: inherit;
    }

    background-color: $navigation_submenu_bg;

    display: none;
    position: absolute;

    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;

    height: calc(100vh - #{$navigation_height_responsive});
    overflow-y: auto;

    left: 0;
    right: 0;
    top: 0;

    z-index: 9998;

    & > .container {
      padding-left: 2.25rem;
      padding-right: 2.25rem;
    }

    &--producttitle {
      font-size: 2rem;
    }

    &--productimage {
      height: 200px;
    }

    &--productparagraph {
      font-weight: 100;
      font-size: 0.875rem;
    }

    &--productheadline {
      font-size: 2.5rem;
    }

    &--productbutton {
      color: theme-color('ci-orange');
      width: 100%;
    }

    &--hr {
      border: 1px solid $navigation-submenu-hr;
    }

    &--image {
      width: 100%;
      height: auto;
    }

    &--fullimage {
      min-height: 500px;
    }

    &--headline {

      align-self: center;
      border-bottom: 1px solid theme-color('secondary-dark');

      & > h6 {
        font-size: 1rem;
        padding: 1rem 0.5rem;
      }


      @include media-breakpoint-up(lg) {

        & {
          border-bottom: none;
        }

        & > h6 {
          font-size: inherit;
          font-family: $font-family-secondary;
          padding: 0;
        }
      }
    }

    &--column {

      & > ul {
        display: none;
      }

      &:first-child {
        border-top: 1px solid theme-color('secondary-dark');
      }

      @include media-breakpoint-up(lg) {

        padding: 0 2rem !important;
        border-bottom: none;

        & > ul {
          display: inherit;
        }

        &:first-child {
          padding-left: 0 !important;
          border-top: inherit;
        }

        &:last-child {
          border-right: none;
        }
      }
    }

    &--border {
      @include media-breakpoint-up(lg) {
        border-right: 1px solid theme-color('secondary-dark');
      }
    }

    &--category {
      font-size: 1rem;
      margin-bottom: 0;

      & > .btn__icon {
        //display: none;
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: 0.5rem;

        & > .btn__icon {
          display: inline-block;
        }
      }
    }

    &--title {
      font-weight: bold;
      font-size: 0.75em;

      text-transform: uppercase;
    }

    &--item {
      @extend .nav-item;

      font-weight: normal;
    }

    &--link {
      @extend .nav-link;

      font-size: 0.75em;

      padding-top: 0;
      padding-bottom: 0;

      padding-left: 1.25rem !important;

      &:before {

        content: ">";
        line-height: 1em;
        margin-right: 0.3em;
      }

      &__icon {
        align-self: center;

        @include media-breakpoint-up(lg) {
          display: none;
        }
      }
    }
}
