/* Custom Bootstrap Code */
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins.scss';

$font-family-sans-serif:      "RBNo2.1b", Arial, sans-serif;
$font-family-secondary:       "CenturyGothic", Arial, sans-serif;

$font-family-base:            $font-family-secondary;

$enable-responsive-font-sizes: false;

/* increase the width of the grid lg and xl breakpoints */
/*$grid-breakpoints: (
  lg: 1024px,
  xl: 1366px
);*/

/* increase container width for the wider lg and xl breakpoints */
$container-max-widths: (
  lg: 1050px,
  xl: 1400px
);

// Buttons
$btn-padding-y:               1.375rem;
$btn-padding-x:               2.75rem;
$btn-font-family:             $font-family-secondary;
$btn-font-size:               1.125rem;
$btn-line-height:             1.125rem;
$btn-white-space:             nowrap;
$btn-font-weight:             400;

// Headings
$headings-font-family:        $font-family-sans-serif;

// Links
$link-color:                  $white;
$link-decoration:             none;
$link-hover-decoration:       underline;

// Breadcrump
$breadcrumb-divider:          quote(">");
$breadcrumb-bg:               inherit;
